import React from "react"
import styled from "styled-components"

const Footer = props => (
  <FooterWrapper id={props.id} props={props}>{props.children}</FooterWrapper>
)

const FooterWrapper = styled.div`
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    color: white;
    text-align: center;
    width: 100vw;
    padding: 20px;
    margin-top: 40px;
`

export default Footer