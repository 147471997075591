import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Helmet from "react-helmet"
import Footer from "../components/footer"
import Navigation from "../components/nav"

class Layout extends React.Component {

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`

    // if (location.pathname === rootPath || location.pathname === blogPath) {
    //   header = (
    //     <h1
    //       style={{
    //         ...scale(1.5),
    //         marginBottom: rhythm(1.5),
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `inherit`,
    //         }}
    //         to={location.pathname === blogPath ? `/blog/` : `/`}
    //       >
    //         {title}
    //       </Link>
    //     </h1>
    //   )
    // } 
    return (
        
        <main>      
            <Helmet>
                <script src="https://embed.twitch.tv/embed/v1.js" type="text/javascript" />
            </Helmet>

            <Navigation></Navigation>

            {children}
        
            <Footer>
                <strong>Tajwar Shelim</strong>
                <small className="d-block"><em>Website created by Jamie Blue, powered by Gatsby</em></small>
            </Footer>
        </main>
    )
  }
}

export default Layout
