import React, { useEffect } from "react"
import styled from "styled-components"

const Navigation = ({props}) => {
    return (
  <React.Fragment>
    <NavWrapper>
        <a href="/">
            <Logo>Tajwar <Highlighted>Shelim</Highlighted></Logo>
        </a>
        <MainLinks>
            <NavLink className="navigation-link" href="/about">ABOUT</NavLink>
            <NavLink className="navigation-link" href="/blog">BLOG</NavLink>
            <NavLink className="navigation-link" href="/causes">ACTIVISM</NavLink>
            <NavLink className="navigation-link" href="/your-rights">YOUR RIGHTS</NavLink>
        </MainLinks>

        <Socials>
            <NavLink href="https://www.facebook.com/tajwar.shelim"><i className="fab fa-facebook"></i></NavLink>
            <NavLink href="https://www.instagram.com/tajwarshelim/"><i className="fab fa-instagram"></i></NavLink>
            <NavLink href="https://twitter.com/TajwarShelim"><i className="fab fa-twitter"></i></NavLink>
            <NavLink href=""><i className="fab fa-twitch"></i></NavLink>
        </Socials>

        <Menu>
            <NavLink onClick={openMobMenu} id="mobileMenuBars"><i className="fas fa-bars"></i></NavLink>
        </Menu>
    </NavWrapper>

    <MobileMenu id="mobileMenu">
        <NavLink onClick={closeMobMenu} id="closeMenu"><i className="fas fa-times"></i></NavLink>
        <NavLink className="navigation-link" href="/">HOME</NavLink>
        <NavLink className="navigation-link" href="/about">ABOUT</NavLink>
        <NavLink className="navigation-link" href="/blog">BLOG</NavLink>
        <NavLink className="navigation-link" href="/causes">ACTIVISM</NavLink>
        <NavLink className="navigation-link" href="/your-rights">YOUR RIGHTS</NavLink>

        <div id="mobileSocials">
            <NavLink href="https://www.facebook.com/tajwar.shelim"><i className="fab fa-facebook"></i></NavLink>
            <NavLink href="https://www.instagram.com/tajwarshelim/"><i className="fab fa-instagram"></i></NavLink>
            <NavLink href="https://twitter.com/TajwarShelim"><i className="fab fa-twitter"></i></NavLink>
            <NavLink href=""><i className="fab fa-twitch"></i></NavLink>
        </div>
    </MobileMenu>

    <div id="webpage_sunglasses">
    </div>
  </React.Fragment>
    )
    }

function openMobMenu() {
    var menu = document.querySelector('#mobileMenu');
    menu.style.display = 'flex';

    document.querySelector('#webpage_sunglasses').style.opacity = '0.7';
    document.querySelector('#webpage_sunglasses').style.zIndex = '998';
}

function closeMobMenu() {
    var menu = document.querySelector('#mobileMenu');
    
    menu.style.display = 'none';
    document.querySelector('#webpage_sunglasses').style.opacity = '0';
}

const NavNotification = styled.div`
    z-index: 999;
    position: fixed;
    width: 100vw;
    top: 60px;
    left: 0;
    right: 0;
    background: black;
    text-align: center;
    color: white;
    font-weight: bold;
    padding-top: 4px;

    @media (max-width: 800px) {
        padding: 6px;
        font-size: 12px;
    }
`

const MobileMenu = styled.div `
    background: black;
    z-index: 9999;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    width: 90vw;
    display: none;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-left: 30px;
    transition: ease-in-out 0.3s;

    .navigation-link {
        font-size: 30px;
        display: flex;
    }

    #closeMenu {
        font-size: 35px;
        position: absolute;
        top: 20px; 
        right: 20px;
        cursor: pointer;
    }

    #mobileSocials {
        margin-top: 25px;

        i {
            font-size: 25px;
        }
    }
`

const Socials = styled.div`
    background: black;
    border-left: 1px solid var(--primary-blue);
    height: 100%;
    display : flex;
    align-items : center;
    justify-content: center;
    padding: 0px 55px;
    margin-left: auto;

    a {
        font-size: 25px;
        padding: 5px;
        color: white;

        i {
            transition: 0.3s;
        }
    }

    a:hover {
        i {
            transform: translateY(-5px);
            color: var(--primary-blue);
            transition: 0.3s;
        }
    }

    @media (max-width: 800px) {
        display: none;
    }

    @media (max-width: 1040px) {
        padding: 0px 20px;
    }
`

const Menu = styled.div`
    background: var(--primary-blue);
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0px 55px;
    margin-left: auto;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    color: white;

    #mobileMenuBars {
        cursor: pointer;
    }

    a {
        font-size: 35px;
        padding: 5px;
        color: white;

        i {
            transition: 0.3s;
        }
    }

    a:hover {
        i {
            color: black;
            transition: 0.3s;
        }
    }

    @media (max-width: 768px) {
        display: flex;
    }
`

const Highlighted = styled.span`
    color: var(--primary-blue);
`

const Logo = styled.h1`
    display: inline-block;
    font-family: Montserrat, 'sans serif';
    font-weight: bolder;
    color: white;
    padding: 15px;
    margin-top: 5px;

    @media (max-width: 1240px) {
        margin: 0;
        font-size: 22px;
    }
`

const NavWrapper = styled.nav`
    z-index: 999;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    border-bottom: 1px solid var(--primary-blue);
    background: black;
    color: white;
    font-size: "15px";
    display : flex;
    align-items : center;
    justify-content: center;
`
const MainLinks = styled.div`
    flex: 1; 
    text-align: center;

    @media (max-width: 768px) {
        display: none;
    }
`

const NavLink = styled.a`       
    box-shadow: none;
    text-decoration: none;
    margin: 0px 3%;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 15px;
    transition: 0.3s;

    &.highlighted-link {
        background: var(--primary-blue);
        color: white;
        border-radius: 100px;
    }

    &.highlighted-link:hover {
        color: white;
        background: #2f75b6;
    }

    &:hover {
        cursor: pointer;
        color: var(--primary-blue);
        text-decoration: none;
        transition: 0.3s;
    }

    i {
        margin-right: 5px;
    }

    @media (max-width: 845px) {
        padding: 5px 10px;
    }

    @media (max-width: 1430px) {
        margin: 0px 5px;
    }

    @media (max-width: 1240px) {
        margin: 0;
    }

    @media (max-width: 1040px) {
        font-size: 14px;
    }
`

export default Navigation
